<template>
  <div
    class="relative rounded-lg px-3 py-2.5 hover:bg-gray-200 hover:bg-opacity-50 group w-full flex items-center text-left gap-3"
    :class="{ 'cursor-default': !allowOpen }"
  >
    <button class="flex-1 w-full flex items-center text-left gap-3" @click="allowOpen ? $emit('open') : ''">
      <div class="flex-shrink-0 font-medium flex items-center gap-1">
        <fw-icon-mail-sent class="w-5 h-5 text-gray-500" />
      </div>

      <div class="flex-1 text-sm font-medium flex gap-3">
        <v-clamp v-if="invite.email" autoresize :max-lines="1">
          {{ invite.email }}
        </v-clamp>
      </div>
      <div class="w-32 flex flex-shrink-0">
        <fw-tag :type="colors[invite.status]" size="xs">
          {{ $t(`status.${invite.status}`) }}
        </fw-tag>
      </div>
      <div v-if="invite.invite_sent_at != null" class="w-52 text-xs text-gray-500 font-normal justify-end">
        <div>{{ $t('resendAt') }}<br />{{ invite.invite_sent_at | formatDateTime }}</div>
      </div>
      <div v-else class="w-52 text-xs text-gray-500 font-normal justify-end">
        <div>Convite ainda não enviado.</div>
      </div>
    </button>
    <div class="w-10">
      <b-dropdown v-if="canResendInvite || canRemove" aria-role="list" position="is-bottom-left">
        <fw-button-dropdown slot="trigger" :chevron="false" type="simple" class="flex flex-col" label="Mais opções">
          <fw-icon-more class="w-5 h-5" />
        </fw-button-dropdown>
        <b-dropdown-item v-if="canResendInvite" custom aria-role="menuitem" class="paddingless">
          <fw-button type="basic-action" custom-class="w-full" @click.native="$emit('resend')"
            >Re-enviar convite</fw-button
          >
        </b-dropdown-item>
        <b-dropdown-item v-if="canRemove" custom aria-role="menuitem" class="paddingless">
          <fw-button type="basic-action" custom-class="w-full" @click.native="$emit('delete', invite)"
            >Remover</fw-button
          >
        </b-dropdown-item>
      </b-dropdown>
    </div>
  </div>
</template>

<script>
//import { TOURNAMENT_STATUS_TAG_COLORS } from '@/utils/index.js'
import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
export default {
  name: 'RecordInvite',
  props: {
    invite: {
      type: Object
    },
    leagues: {
      type: Object
    },
    allowResend: {
      type: Boolean,
      default: false
    },
    allowOpen: {
      type: Boolean,
      default: false
    },
    canRemove: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      colors: {
        pending: 'orange',
        denied: 'light-danger',
        confirmed: 'primary',
        invalidated: 'gray'
      }
    }
  },

  computed: {
    language() {
      return this.$store.state.language || 'pt'
    },

    canInviteAgain() {
      return (
        this.invite.status !== 'confirmed' &&
        this.invite.status !== 'invalidated' &&
        Dates.nowDiff(this.invite.invite_sent_at, 'hours') < 0
      )
    },

    canResendInvite() {
      return this.allowResend && this.canInviteAgain
    }
  },

  methods: {
    resendInvite() {
      this.$buefy.dialog.confirm({
        title: 'Reenviar convite',
        message: 'Tem a certeza que deseja reenviar o convite para ' + this.invite.email + '?',
        confirmText: 'Reenviar',
        onConfirm: async () => {
          this.$emit('resend', this.invite)
        }
      })
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "sendAt": "Convite enviado a",
    "resendAt": "Último convite enviado em",
    "status": {
      "pending": "Pendente",
      "denied": "Recusado",
      "confirmed": "Confirmado",
      "invalidated": "Invalidado"
    }
  },
  "en": {
    "sendAt": "Invite sent at",
    "resendAt": "Last invite sent at",
    "status": {
      "pending": "Pending",
      "denied": "Refused",
      "confirmed": "Confirmed",
      "invalidated": "Invalidated"
    }
  }
}
</i18n>
